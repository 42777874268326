<template>
  <window-default ref="window" :resolve="find" :proccessData="proccessData" :mock="mock" :props="props" v-slot="{data}" @loaded="(obj) => model = obj" @save="save">
    <window-body class="wrapper-lg">
      <e-row mr>
        <e-col size="1">
          <erp-s-field
              label="ID:"
            >
            <erp-input v-model="model.id" size="2" readonly />
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row mr>
        <e-col>
          <erp-s-field
              label="Descrição:"
          >
            <erp-input autofocus v-model="model.nome" size="2" />
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row mr>
        <e-col>
          <erp-s-field
              label="Ativo:"
          >
            <erp-checkbox v-model="model.active" />
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row mr>
        <e-col>
          <erp-s-field
              label="Global:"
          >
            <erp-checkbox v-model="model.global" />
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row mr>
        <e-col class="font-10 text-blue-grey-6 m-t-n-xs">
          Marque o campo acima se este acessório deve aparecer em qualquer tipo de bem do sistema
        </e-col>
      </e-row>

      <e-row v-if="!model.global" mr>
        <e-col>
          <erp-s-field
              label="Tipos de Bem:"
          >
            <erp-select multiple v-model="model.tiposBem" placeholder="Nenhum" :options="tiposFiltered"
                        :loading="loadingTipos" size="2">
              <template v-slot:itemLabel="props">
                <span class="erp-select-level" :class="{'font-bold': Number(props.opt.level) === 0}" :style="'padding: 0 0 0 ' + (10 * Number(props.opt.level)) + 'px' ">{{props.opt.label}}</span>
              </template>
            </erp-select>
          </erp-s-field>
        </e-col>
      </e-row>

    </window-body>
  </window-default>
</template>

<script>
import {WindowBody, ErpSField, ErpInput, ErpCheckbox, ErpSelect} from 'uloc-vue-plugin-erp'
import WindowDefault from '../../../../../reuse/window/Default'
import ERow from '../../../../layout/components/Row'
import ECol from '../../../../layout/components/Col'
import {
  findAcessorio as find,
  listTiposBem,
  newAcessorio as _new,
  updateAcessorio as update
} from '../../../../../domain/cadastro/services'
import save from '../../../helpers/save'
import {treeCheck} from '../../tiposbem/helpers/treeCheck';

let mock = {
  id: null,
  nome: null,
  global: false,
  active: true,
  tiposBem: []
}

export default {
  name: 'AcessorioWindow',
  components: {ECol, ERow, WindowDefault, WindowBody, ErpSField, ErpInput, ErpCheckbox, ErpSelect},
  props: ['props'],
  data () {
    return {
      model: JSON.parse(JSON.stringify(mock)),
      loadingTipos: false,
      tipos: []
    }
  },
  mounted () {
    listTiposBem(200, 1, null, true)
      .then(({data}) => {
        this.tipos = data
        this.loadingTipos = false
      })
      .catch(error => {
        console.error(error)
      })
  },
  computed: {
    find () {
      return find
    },
    mock () {
      return mock
    },
    tiposFiltered () {
      return this.treeCheck(this.tipos)
    }
  },
  methods: {
    update: update,
    new: _new,
    save: save,
    proccessData (data) {
      let _mock = JSON.parse(JSON.stringify(this.mock))
      data = Object.assign(_mock, data)

      if (data.tiposBem && Array.isArray(data.tiposBem)) {
        data.tiposBem = data.tiposBem.map(tipo => {
          return tipo.id
        })
      }
      console.log(data)
      return data
    },
    treeCheck: treeCheck
  }
}
</script>
